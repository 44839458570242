import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Header from '../components/header'


const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "cup.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)

const Black = () => (
	<div className='absolute bg-black-60 mh3-ns absolute--fill'>&nbsp;</div>
)


const IndexPage = () => (
  <Layout>
  	<div className='pb5'>
  		<div className='w-100 w-80-ns center'>
  			<Header />
		  	<div className='cf mt4 nh3-ns'>
		  		<div className='w-third fl ph3-ns relative'>
		  			<Image />
		  			<Black />
		  		</div>
		  		<div className='w-third fl ph3-ns'>
		  			<Image />
		  		</div>
		  		<div className='w-third fl ph3-ns relative'>
		  			<Image />
		  			<Black />
		  		</div>
		  	</div>
		  	<div className='cf mt3 mh3 mh0-ns'>
		  		<div className='w-100 cf pv3 tc'>
		  			<p className='near-black lh-copy f2 barlow mt0 mb2 pa3 mh7-l'>Custom engraving<br className='db dn-ns'/> done right.</p>
		  			<p className='roboto black-60 lh-copy w-90 w-70-m w-50-l center'>Our industrial laser cutters can engrave almost any kind of cup imaginable. Yeti, Rtic, S'well &mdash; you name it, we can engrave it. Cups are all that we do, too, so you won't have to wait. Our average turnaround time is 3 business days.</p>
		  		</div>
		  		<div className='w-100 cf mv4 pv3 tc ba b--black-05'>
		  			<p className='near-black lh-copy f2 barlow mt0 mb2 pa3 mh7-l'>Bring your cup, or use one of ours.</p>
		  			<p className='roboto black-60 lh-copy w-90 w-70-m w-50-l center'>Need a couple of tumblers engraved? We can do that. Want to reward hundreds of your customers with engraved tumblers? We can do that too — and we'll even source the tumblers.</p>
		  		</div>
		  		<div className='w-100 cf pv3 tc'>
		  			<p className='near-black lh-copy f2 barlow mt0 mb2 pa3 mh7-l'>Let's get started.</p>
		  			<p className='roboto black-60 lh-copy w-90 w-70-m w-50-l center'>Tell us about your project and we'll get back to you with a quote within one business day.</p>
		  			<Link className='dib white bg-near-black mt4 pa2 ph3 ttu tracked roboto fw4 f6 f5-l f4-xl no-underline dim' to="/custom-engraving/">Start Here</Link>
		  		</div>
		  	</div>
  		</div>
	</div>
  </Layout>
)

export default IndexPage
